import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';
import { flatten, map, zipObj } from 'ramda';

const WAREHOUSES = gql`
  query warehouses($warehouseAccountId: ID!) {
    warehouses(warehouseAccountId: $warehouseAccountId) {
      id
      warehouseAccountId
      warehouseAccountName
      warehouse_code
      warehouse_name_en
      warehouse_name_cn
      remark
      country
      state
      city
      district
      post_code
      street
      contact
      aisles
      units_per_aisle
      shelves_per_unit
      sections_per_shelf
    }
  }
`;

const ADD_WAREHOUSE = gql`
  mutation addWarehouse(
    $warehouseAccountId: ID!
    $warehouse_code: ID!
    $warehouse_name_en: String!
    $remark: String
    $country: String!
    $state: String!
    $city: String!
    $district: String
    $post_code: String!
    $street: String!
    $contact: String
    $aisles: Int!
    $units_per_aisle: Int!
    $shelves_per_unit: Int!
    $sections_per_shelf: Int!
  ) {
    addWarehouse(
      warehouseAccountId: $warehouseAccountId
      warehouse_code: $warehouse_code
      warehouse_name_en: $warehouse_name_en
      remark: $remark
      country: $country
      state: $state
      city: $city
      district: $district
      post_code: $post_code
      street: $street
      contact: $contact
      aisles: $aisles
      units_per_aisle: $units_per_aisle
      shelves_per_unit: $shelves_per_unit
      sections_per_shelf: $sections_per_shelf
    ) {
      id
      warehouseAccountId
      warehouse_code
      warehouse_name_en
      remark
      country
      state
      city
      district
      post_code
      street
      contact
      aisles
      units_per_aisle
      shelves_per_unit
      sections_per_shelf
    }
  }
`;

const SET_WAREHOUSE = gql`
  mutation setWarehouse(
    $id: ID!
    $warehouseAccountId: ID!
    $warehouse_name_en: String!
    $remark: String
    $country: String!
    $state: String!
    $city: String!
    $district: String
    $post_code: String!
    $street: String!
    $contact: String
    $aisles: Int!
    $units_per_aisle: Int!
    $shelves_per_unit: Int!
    $sections_per_shelf: Int!
  ) {
    setWarehouse(
      id: $id
      warehouseAccountId: $warehouseAccountId
      warehouse_name_en: $warehouse_name_en
      remark: $remark
      country: $country
      state: $state
      city: $city
      district: $district
      post_code: $post_code
      street: $street
      contact: $contact
      aisles: $aisles
      units_per_aisle: $units_per_aisle
      shelves_per_unit: $shelves_per_unit
      sections_per_shelf: $sections_per_shelf
    ) {
      id
      warehouseAccountId
      warehouse_code
      warehouse_name_en
      remark
      country
      state
      city
      district
      post_code
      street
      contact
      aisles
      units_per_aisle
      shelves_per_unit
      sections_per_shelf
    }
  }
`;

const DELETE_WAREHOUSE = gql`
  mutation deleteWarehouse($warehouse_code: ID!, $warehouseAccountId: ID!) {
    deleteWarehouse(warehouse_code: $warehouse_code, warehouseAccountId: $warehouseAccountId) {
      warehouse_code
    }
  }
`;

export class WarehousesStore {
  loading = false;
  error = null;
  cachedWarehouses = null;
  client = null;
  warehouseAccountStore = null;

  constructor(client, warehouseAccountStore, storeStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;
    this.storeStore = storeStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
        storeStore: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  clearCache() {
    this.cachedWarehouses = null;

    // to clear the warehouse settings in stores
    this.storeStore.clearCache();
  }

  get warehouses() {
    if (!this.cachedWarehouses) {
      this.fetchItems();
    }
    return flatten(Object.values(this.cachedWarehouses || {}));
  }

  get warehousesByAccountId() {
    if (!this.cachedWarehouses) {
      this.fetchItems();
    }
    return this.cachedWarehouses || [];
  }

  get warehouseCodes() {
    return map((w) => w.warehouse_code, this.warehouses);
  }

  get warehouseNames() {
    return map((w) => w.warehouse_name_en, this.warehouses);
  }

  getWarehouseNameEn(warehouseCode, warehouseAccountId) {
    return (
      flatten(Object.values(this.warehouses)).find((w) => {
        // FIXME: we need to refactor all places where getWarehouseNameEn is used to pass warehouseAccountId
        if (warehouseAccountId) {
          return w.warehouse_code === warehouseCode && w.warehouseAccountId === warehouseAccountId;
        } else return w.warehouse_code === warehouseCode;
      })?.warehouse_name_en || warehouseCode
    );
  }

  getWarehouse(warehouseCode, warehouseAccountId) {
    return Object.values(this.warehouses).find(
      (w) => w.warehouse_code === warehouseCode && w.warehouseAccountId === warehouseAccountId,
    );
  }

  *fetchItems() {
    try {
      this.loading = true;
      const warehouseAccountIds = this.warehouseAccountStore.warehouseAccountIds;
      const data = yield Promise.all(
        warehouseAccountIds.map((id) =>
          this.client.query({
            query: WAREHOUSES,
            variables: {
              warehouseAccountId: id,
            },
            fetchPolicy: 'no-cache',
          }),
        ),
      );
      this.cachedWarehouses = zipObj(
        warehouseAccountIds,
        data.map((d) => d.data.warehouses),
      );
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *addWarehouse(
    warehouseAccountId,
    warehouse_code,
    warehouse_name_en,
    country,
    state,
    city,
    district,
    post_code,
    street,
    contact,
    remark,
    aisles,
    units_per_aisle,
    shelves_per_unit,
    sections_per_shelf,
  ) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: ADD_WAREHOUSE,
        variables: {
          warehouseAccountId,
          warehouse_code,
          warehouse_name_en,
          remark,
          country,
          state,
          city,
          district,
          post_code,
          street,
          contact,
          aisles,
          units_per_aisle,
          shelves_per_unit,
          sections_per_shelf,
        },
      });
      this.cachedWarehouses = null;
      this.error = null;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *setWarehouse(
    id,
    warehouseAccountId,
    warehouse_name_en,
    country,
    state,
    city,
    district,
    post_code,
    street,
    contact,
    remark,
    aisles,
    units_per_aisle,
    shelves_per_unit,
    sections_per_shelf,
  ) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: SET_WAREHOUSE,
        variables: {
          id,
          warehouseAccountId,
          warehouse_name_en,
          remark,
          country,
          state,
          city,
          district,
          post_code,
          street,
          contact,
          aisles,
          units_per_aisle,
          shelves_per_unit,
          sections_per_shelf,
        },
      });
      this.cachedWarehouses = null;
      this.error = null;
    } catch (error) {
      this.error = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *deleteWarehouse(warehouseAccountId, warehouse_code) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: DELETE_WAREHOUSE,
        variables: {
          warehouseAccountId,
          warehouse_code,
        },
      });
      this.cachedWarehouses = null;
      this.error = null;
    } catch (error) {
      this.error = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }

  getStoreWhitelistedWarehouse(storeId) {
    const warehouseCodesWhiteList = this.storeStore.getStoreWarehouseCodesById(storeId);
    return warehouseCodesWhiteList
      ? this.warehouses.filter((w) => warehouseCodesWhiteList.includes(w.warehouse_code))
      : this.warehouses;
  }

  getAllStoreWhitelistedWarehouse() {
    const storeIds = this.storeStore.enabledStoreIds;
    const warehouseCodesWhiteList = flatten(
      storeIds.map((storeId) => this.storeStore.getStoreWarehouseCodesById(storeId)),
    );
    const result = warehouseCodesWhiteList
      ? this.warehouses.filter((w) => warehouseCodesWhiteList.includes(w.warehouse_code))
      : this.warehouses;
    return result;
  }
}
